jQuery(document).ready(function($) {
	if ( $('.parallax-max-animated-element').length ) { // Only initiate if at least one element is present.
		// console.log('starting animation');
		$(function() {
			setInterval(function() {
				$('.parallax-max-animated-element').animate({ left: $(window).width() + 'px' }, 200000, 'linear', function() {
					$(this).css({ left: - $(this).width() + 'px' });
				});
			}, 10);
		});
	}

	$(window).on('scroll load', function() { // Only trigger when the page loads or the window scrolls.
		// Animate the text wrapper, fading in and sliding from the right to the left
		if ( $('.parallax-max-text-wrapper').length ) { // Only initiate if at least one element is present.
			$('.parallax-max-text-wrapper').each(function(i) {
				var objectBottom = $(this).offset().top;
				var windowBottom = $(window).scrollTop() + $(window).height();
				// Retrieve the optional speed parameter so objects can fade in at different speeds
				// The attribute is data-speed, but we use .data so it is retrieved as a number instead of a string.
				var objectSpeed = $(this).data('speed');
				if ( !objectSpeed ) { objectSpeed = 900 } // Default speed
				if ( $(window).width() > 1200 && windowBottom > objectBottom ) {
				// console.log('fading in, boss');
				$(this).animate({
					'opacity': 1,
					'right': 200,
				}, objectSpeed, "linear");

				}
			});
		}
		// Animate the background ocean bit
		$(".parallax-max-wrapper").each( function() {
			// Detect how far we've scrolled.
			var scrolledHeight = window.pageYOffset,
			limit = this.offsetTop + this.offsetHeight;
			//evaluates to true only when parallax element reaches the top of the viewport and until it is in the viewport.
			// We subtract the browser's viewport height from the first measurement, and add it to the second, so the parallax effect starts as soon as the top of the section comes into the viewport. Otherwise it only starts when the top of the section meets the top of the viewport.
			if ( scrolledHeight > this.offsetTop - document.documentElement.clientHeight && scrolledHeight <= limit + document.documentElement.clientHeight ) {
				this.style.backgroundPositionY = (scrolledHeight - this.offsetTop) /10+ "px";
			} 
			else {
				this.style.backgroundPositionY= "0";
			}
		});
	});
}); /* end of as page load scripts */